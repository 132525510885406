import React, { useEffect, useState } from 'react';
import {
  Button,
  TextField,
  Container,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import { db } from '../firebase2';
import {
  setDoc,
  doc,
  collection,
  query,
  where,
  getDocs,
  updateDoc,
} from 'firebase/firestore';
import './MyList.css';

const MyListReseller = () => {
  const [macAddressUpload, setMacAddressUpload] = useState('');
  const [macAddressDelete, setMacAddressDelete] = useState('');
  const [url1, setUrl1] = useState('');
  const [url2, setUrl2] = useState('');
  const [url3, setUrl3] = useState('');
  const [openModal, setOpenModal] = useState(false);
  const [openModalDelete, setOpenModalDelete] = useState(false);
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [isLGDevice, setIsLGDevice] = useState(false);

  const [username1, setUsername1] = useState('');
  const [password1, setPassword1] = useState('');
  const [username2, setUsername2] = useState('');
  const [password2, setPassword2] = useState('');
  const [username3, setUsername3] = useState('');
  const [password3, setPassword3] = useState('');

  const handleCloseModalDelete = () => {
    setOpenModalDelete(false);
  };

  const handleSubmitUpload = (e) => {
    e.preventDefault();
    handleUpload();
  };

  const handleSubmitDelete = (e) => {
    e.preventDefault();
    handleDelete();
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleMacAddressChange = (event) => {
    const input = event.target.value;
    // Allow only valid characters in a MAC address
    const sanitizedInput = input.replace(/[^a-fA-F0-9]/g, '');
    // Add colons automatically
    const formattedInput = sanitizedInput
      .substring(0, 12) // Limit input to a maximum of 12 characters
      .match(/.{1,2}/g);
    if (formattedInput) {
      // Check if formattedInput is not null
      const macAddress = formattedInput.join(':').toUpperCase();
      setMacAddressUpload(macAddress);
    } else {
      setMacAddressUpload('');
    }
  };

  const handleCheckboxChange = (event) => {
    setMacAddressUpload('');
    setMacAddressDelete('');
    setIsLGDevice(event.target.checked);
  };

  const handleLGAddressChange = (event) => {
    const macAddress = event.target.value;

    if (macAddress) {
      setMacAddressUpload(macAddress);
    } else {
      setMacAddressUpload('');
    }
  };

  const handleMacAddressDeleteChange = (event) => {
    const input = event.target.value;
    // Allow only valid characters in a MAC address
    const sanitizedInput = input.replace(/[^a-fA-F0-9]/g, '');
    // Add colons automatically
    const formattedInput = sanitizedInput
      .substring(0, 12) // Limit input to a maximum of 12 characters
      .match(/.{1,2}/g);
    if (formattedInput) {
      // Check if formattedInput is not null
      const macAddress = formattedInput.join(':').toUpperCase();
      setMacAddressDelete(macAddress);
    } else {
      setMacAddressDelete('');
    }
  };

  const handleLGAddressDeleteChange = (event) => {
    const input = event.target.value;

    if (input) {
      // Check if formattedInput is not null
      const macAddress = input;
      setMacAddressDelete(macAddress);
    } else {
      setMacAddressDelete('');
    }
  };

  const handleUpload = async () => {
    try {
      // Überprüfen, ob die MAC-Adresse bereits existiert

      //   const querySnapshot = await getDocs(
      //     query(
      //       collection(db, 'customers'),
      //       where('mac', '==', macAddressUpload),
      //     ),
      //   );

      //   if (!querySnapshot.empty) {
      //     // Das Dokument existiert bereits, also aktualisiere nur die Playlist-URLs
      //     const docRef = doc(db, 'customers', querySnapshot.docs[0].id);
      //     const docData = {
      //       playlistUrl: url1 || querySnapshot.docs[0].data().playlistUrl,
      //       playlistUrl2: url2 || querySnapshot.docs[0].data().playlistUrl2,
      //       playlistUrl3: url3 || querySnapshot.docs[0].data().playlistUrl3,
      //       newUrlUpload: true,
      //     };
      //     await updateDoc(docRef, docData);
      //   } else {
      //     // Das Dokument existiert nicht, also gib eine Fehlermeldung aus
      //     throw new Error('Die MAC-Adresse wurde nicht gefunden.');
      //   }

      setOpenModal(true);
    } catch (error) {
      setOpenModal(false);
      setOpenModalDelete(false);
      setOpenErrorModal(true);
    }
  };

  const handleDelete = async () => {
    try {
      // Überprüfen, ob die MAC-Adresse existiert
      const querySnapshot = await getDocs(
        query(
          collection(db, 'customers'),
          where('mac', '==', macAddressDelete),
        ),
      );

      if (!querySnapshot.empty) {
        // Das Dokument existiert, also aktualisiere nur die Playlist-URLs
        const docRef = doc(db, 'customers', querySnapshot.docs[0].id);
        await updateDoc(docRef, {
          playlistUrl: '',
          playlistUrl2: '',
          playlistUrl3: '',
          newUrlUpload: true,
        });
        setOpenModalDelete(true);
      } else {
        // Das Dokument existiert nicht
        console.log(
          'Das Dokument mit der angegebenen MAC-Adresse wurde nicht gefunden.',
        );
      }
    } catch (error) {
      console.error('Fehler beim Löschen der Playlist-URLs:', error);
    }
  };

  const isUploadDisabled = !macAddressUpload || !username1 || !password1;

  // Extract MAC address value from URL parameter
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const macAddress = urlParams.get('mac');
    const lgDevice = urlParams.get('lg') || 'false';
    if (macAddress && lgDevice == 'false') {
      handleMacAddressChange({ target: { value: macAddress } });
    }
    if (lgDevice && lgDevice === 'true') {
      setIsLGDevice(true);
      handleLGAddressChange({ target: { value: macAddress } });
    }
  }, []);

  return (
    <Container className="mylist-container" maxWidth="sm">
      <Typography variant="h4" component="h1" gutterBottom>
        Manage Playlists
      </Typography>
      <p></p>

      <form className="mylist-form" onSubmit={handleSubmitUpload}>
        <FormControlLabel
          control={
            <Checkbox
              checked={isLGDevice}
              onChange={handleCheckboxChange}
              color="primary"
            />
          }
          label="I am using an LG or Android TV"
        />
        {!isLGDevice ? (
          <TextField
            label="MAC Address"
            fullWidth
            margin="normal"
            value={macAddressUpload}
            onChange={handleMacAddressChange}
          />
        ) : (
          <TextField
            label={isLGDevice ? 'Enter ID' : 'Enter MAC'}
            fullWidth
            margin="normal"
            value={macAddressUpload}
            onChange={handleLGAddressChange}
          />
        )}
        <TextField
          label="Username"
          fullWidth
          margin="normal"
          value={username1}
          onChange={(e) => setUsername1(e.target.value)}
        />
        <TextField
          label="Password"
          fullWidth
          margin="normal"
          value={password1}
          onChange={(e) => setPassword1(e.target.value)}
        />

        <Button
          className="mylist-button"
          variant="contained"
          color="primary"
          type="submit"
          disabled={isUploadDisabled}
        >
          Upload
        </Button>
      </form>

      <Dialog open={openModal} onClose={handleCloseModal}>
        <DialogTitle>Upload Successful</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Your playlists have been uploaded successfully.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={openErrorModal} onClose={() => setOpenErrorModal(false)}>
        <DialogTitle>Error</DialogTitle>
        <DialogContent>
          <DialogContentText>The MAC address was not found.</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenErrorModal(false)} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={openModalDelete} onClose={handleCloseModalDelete}>
        <DialogTitle>Delete Successful</DialogTitle>
        <DialogContent>
          <DialogContentText>
            The playlist has been deleted successfully.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModalDelete} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default MyListReseller;
