import React from 'react';
import { Box, Typography, Link } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const Footer = () => {
  const navigate = useNavigate();

  const handleNavigate = (path) => {
    navigate(path);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#191F2A',
        padding: '1rem',
        color: '#ffffff',
      }}
    >
      <Typography variant="body2">
        <Link
          component="button"
          variant="body2"
          onClick={() => handleNavigate('/privacy-policy')}
          sx={{
            color: '#ffffff',
            textDecoration: 'none',
            '&:visited, &:hover, &:active': {
              color: '#ffffff',
            },
          }}
        >
          Privacy Policy
        </Link>
        {'   '}-{'   '}
        <Link
          component="button"
          variant="body2"
          onClick={() => handleNavigate('/legal')}
          sx={{
            color: '#ffffff',
            textDecoration: 'none',
            '&:visited, &:hover, &:active': {
              color: '#ffffff',
            },
          }}
        >
          Legal
        </Link>
        {'   '}-{'   '}
        <Link
          component="button"
          variant="body2"
          onClick={() => handleNavigate('/terms-and-conditions')}
          sx={{
            color: '#ffffff',
            textDecoration: 'none',
            '&:visited, &:hover, &:active': {
              color: '#ffffff',
            },
          }}
        >
          Terms and Conditions
        </Link>
      </Typography>
    </Box>
  );
};

export default Footer;
