import React from 'react';
import Lottie from 'react-lottie';
import animationData from '../assets/animation.json';
import './Home.css';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const Home = () => {
  const navigate = useNavigate();

  const epgData = {
    channel: 'TRT 1',
    title: 'Sample Show',
    description: 'This is a sample show on TRT 1',
    startTime: '2023-04-07T19:00:00',
    endTime: '2023-04-07T20:00:00',
  };

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  const handleActivation = () => {
    navigate('/activation');
  };

  return (
    <div className="container">
      <div className="text-content">
        <div className="com-text">
          <h1>Welcome to Royal IPTV</h1>
          <h3>
            The IPTV Video Player that Elevates Your Streaming Experience!
          </h3>
          <p>
            Unleash the full potential of your IPTV service with Royal
            Videoplayer, a cutting-edge video player designed to optimize your
            viewing pleasure. Immerse yourself in a seamless streaming
            experience that puts you in control.
          </p>
          <p>
            Upgrade your streaming experience with Royal Videoplayer and enjoy
            television like never before!
          </p>
          <p>
            Fire TV Stick APK Download Link:{' '}
            <a href="https://apk.royaliptvapp.com" style={{ color: '#ff99d6' }}>
              https://apk.royaliptvapp.com
            </a>
          </p>
          <Button
            variant="contained"
            color="warning"
            onClick={handleActivation}
          >
            Get Started
          </Button>
          <div className="key-features-container">
            <div className="key-feature-card">
              <span className="icon">📺</span>
              <p>Seamless integration with your playlist</p>
            </div>
            <div className="key-feature-card">
              <span className="icon">🌐</span>
              <p>Watch live TV channels from around the world</p>
            </div>
            <div className="key-feature-card">
              <span className="icon">🎬</span>
              <p>Play movies and series on-demand</p>
            </div>
            <div className="key-feature-card">
              <span className="icon">🔀</span>
              <p>
                Manage multiple playlists for diverse content and easy
                navigation
              </p>
            </div>
            <div className="key-feature-card">
              <span className="icon">📅</span>
              <p>
                Access EPG for real-time program schedules and stay up-to-date
              </p>
            </div>
            <div className="key-feature-card">
              <span className="icon">⚽</span>
              <p>Browse today's soccer games and never miss a match</p>
            </div>
            <div className="key-feature-card">
              <span className="icon">⏯️</span>
              <p>Pause, rewind, and fast-forward with ease</p>
            </div>
            <div className="key-feature-card">
              <span className="icon">📺</span>
              <p>Adaptive bitrate for smooth playback</p>
            </div>
            <div className="key-feature-card">
              <span className="icon">📁</span>
              <p>Organize channels with custom groups for easy access</p>
            </div>
            <div className="key-feature-card">
              <span className="icon">🔍</span>
              <p>Search and filter to find content quickly</p>
            </div>
            <div className="key-feature-card">
              <span className="icon">🎞️</span>
              <p>User-friendly interface for effortless navigation</p>
            </div>
            <div className="key-feature-card">
              <span className="icon">🔐</span>
              <p>
                Set passwords for channels to ensure privacy and control access
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="lottie-container">
        <Lottie options={defaultOptions} />
      </div>
    </div>
  );
};

export default Home;
