import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyCI-qkQRB8Cp0zgUAXJyPfEg06a_xQiXLA",
  authDomain: "royaltvapp-bc006.firebaseapp.com",
  projectId: "royaltvapp-bc006",
  storageBucket: "royaltvapp-bc006.appspot.com",
  messagingSenderId: "1096590505237",
  appId: "1:1096590505237:web:b92ae655c33b916d46a51c",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Cloud Firestore and get a reference to the service
export const db = getFirestore(app);
