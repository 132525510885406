import React, { useEffect, useState } from 'react';
import './NewsTicker.css';

function NewsTicker() {
  const [news, setNews] = useState('');
  const [position, setPosition] = useState(0);

  // Hier können Sie Ihre Nachrichten definieren
  const messages = [
    'Download APK Royal IPTV 2: <a class="link" href="https://apk.royaliptvapp.com" target="_blank" rel="noopener noreferrer">Download</a>',
  ];

  useEffect(() => {
    let interval = setInterval(() => {
      setPosition((prevPosition) => prevPosition + 1);
    }, 3000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    if (position >= messages.length) {
      setPosition(0);
    }
    setNews(messages[position]);
  }, [position]);

  return (
    <div className="news-ticker">
      <span
        className="ticker-text"
        dangerouslySetInnerHTML={{ __html: news }}
      ></span>
    </div>
  );
}

export default NewsTicker;
