import React from 'react';
import Navbar from './Navbar';
import Footer from './Footer';
import './Layout.css';
import NewsTicker from './NewsTicker';

const Layout = ({ children }) => {
  return (
    <div className="layout">
      <NewsTicker />
      <Navbar />
      <div className="content">{children}</div>
      <Footer />
    </div>
  );
};

export default Layout;
