import React from 'react';
import { CssBaseline, ThemeProvider, createTheme } from '@mui/material';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import PrivacyPolicy from './pages/PrivacyPolicy';
import Legal from './pages/Legal';
import TermsAndConditions from './pages/TermsAndConditions';
import Layout from './components/Layout';
import MyList from './pages/MyList';
import animationData2 from './assets/animation2.json';
import Lottie from 'react-lottie';
import Activation from './pages/Activation';
import FAQ from './pages/Faq';
import MyListReseller from './pages/MyListResller';

const theme = createTheme({
  palette: {
    mode: 'dark',
  },
});

function App() {
  const defaultOptions2 = {
    loop: true,
    autoplay: true,
    animationData: animationData2,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  return (
    <>
      <div className="lottie-container-bg">
        <Lottie options={defaultOptions2} />
      </div>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Router>
          <Routes>
            <Route
              path="/"
              element={
                <Layout>
                  <Home />
                </Layout>
              }
            />
            <Route
              path="/mylist"
              element={
                <Layout>
                  <MyList />
                </Layout>
              }
            />
            <Route
              path="/mylistReseller"
              element={
                <Layout>
                  <MyListReseller />
                </Layout>
              }
            />
            <Route
              path="/activation"
              element={
                <Layout>
                  <Activation />
                </Layout>
              }
            />
            <Route
              path="/faq"
              element={
                <Layout>
                  <FAQ />
                </Layout>
              }
            />
            <Route
              path="/privacy-policy"
              element={
                <Layout>
                  <PrivacyPolicy />
                </Layout>
              }
            />
            <Route
              path="/terms-and-conditions"
              element={
                <Layout>
                  <TermsAndConditions />
                </Layout>
              }
            />
            <Route
              path="/legal"
              element={
                <Layout>
                  <Legal />
                </Layout>
              }
            />
          </Routes>
        </Router>
      </ThemeProvider>
    </>
  );
}

export default App;
