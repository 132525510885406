import React from 'react';
import { AppBar, Toolbar, Typography, Button } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useNavigate } from 'react-router-dom';
import './Navbar.css';
import BurgerMenu from './BurgerMenu';

const useStyles = makeStyles({
  toolbar: {
    justifyContent: 'space-between',
  },
});

const Navbar = () => {
  const classes = useStyles();
  const navigate = useNavigate();

  const navigateToWebsite = () => {
    window.location.href = 'https://www.royaliptvapp.com/activation.html';
  };

  const navigateToWebsite2 = () => {
    window.location.href = 'https://www.royaliptvapp.com/activation.html';
  };

  const navigateToWebsite3 = () => {
    window.location.href = 'https://t.me/royaliptvapp';
  };

  const navigateToWebsite4 = () => {
    window.location.href = 'https://www.royaliptvapp.com/mylist';
  };

  const navigateToWebsite7 = () => {
    window.location.href = 'https://www.royaliptvapp.com/reseller';
  };

  const navigateToWebsite5 = () => {
    window.location.href = 'https://isi-appstore.de/';
  };

  const navigateToWebsite6 = () => {
    window.location.href = 'https://royal-tv-app.sleekplan.app/';
  };

  const navigateActivation = () => {
    window.location.href = 'https://isi-appstore.de/';
  };

  return (
    <AppBar position="static" elevation={0}>
      <Toolbar className={classes.toolbar}>
        <Typography
          variant="h6"
          onClick={() => navigate('/')}
          className="navbar-logo"
        >
          Royal IPTV 2.0
        </Typography>
        <div className="navbar-links">
          <Button color="inherit" onClick={() => navigate('/')}>
            Home
          </Button>
          <Button color="inherit" onClick={() => navigate('/faq')}>
            FAQ
          </Button>
          {/* <Button color="inherit" onClick={() => navigate('/mylist')}>
            My List
          </Button>
          <Button color="inherit" onClick={navigateActivation}>
            Activation
          </Button> */}
          <Button color="inherit" onClick={navigateToWebsite4}>
            My List
          </Button>
          <Button color="inherit" onClick={navigateToWebsite5}>
            Activation
          </Button>
          <Button color="inherit" onClick={navigateToWebsite}>
            Royal 1.0
          </Button>
          <Button color="inherit" onClick={navigateToWebsite3}>
            Telegram
          </Button>
          <Button color="inherit" onClick={navigateToWebsite6}>
            Feedback
          </Button>
        </div>
        <BurgerMenu />
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;
