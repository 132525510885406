import React, { useState } from 'react';
import { MdMenu, MdClose } from 'react-icons/md';
import './BurgerMenu.css';

const BurgerMenu = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
    const body = document.querySelector('body');
    body.classList.toggle('noscroll');
  };

  return (
    <div className="burger-menu">
      <div onClick={toggleMenu}>
        <MdMenu size={30} />
      </div>
      {menuOpen && (
        <div className="menu">
          <div className="menu-close" onClick={toggleMenu}>
            <MdClose size={30} />
          </div>
          <a href="/" onClick={toggleMenu}>
            Home
          </a>
          <a href="/faq" onClick={toggleMenu}>
            FAQ
          </a>
          <a href="/mylist" onClick={toggleMenu}>
            My List
          </a>
          <a href="https://isi-appstore.de/" onClick={toggleMenu}>
            Activation
          </a>
          <a
            href="https://www.royaliptvapp.com/activation.html"
            onClick={toggleMenu}
          >
            Royal 1.0
          </a>
          <a href="https://t.me/royaliptvapp" onClick={toggleMenu}>
            Telegram
          </a>
          <a href="https://royal-tv-app.sleekplan.app/" onClick={toggleMenu}>
            Feedback
          </a>
        </div>
      )}
    </div>
  );
};

export default BurgerMenu;
