import React, { useEffect, useState } from 'react';
import {
  Button,
  TextField,
  Container,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import { db } from '../firebase2';
import {
  setDoc,
  doc,
  collection,
  query,
  where,
  getDocs,
  updateDoc,
} from 'firebase/firestore';
import './MyList.css';

const MyList = () => {
  const [macAddressUpload, setMacAddressUpload] = useState('');
  const [macAddressDelete, setMacAddressDelete] = useState('');
  const [url1, setUrl1] = useState('');
  const [url2, setUrl2] = useState('');
  const [url3, setUrl3] = useState('');
  const [openModal, setOpenModal] = useState(false);
  const [openModalDelete, setOpenModalDelete] = useState(false);
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [isLGDevice, setIsLGDevice] = useState(false);

  const handleCloseModalDelete = () => {
    setOpenModalDelete(false);
  };

  const handleSubmitUpload = (e) => {
    e.preventDefault();
    handleUpload();
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleMacAddressChange = (event) => {
    const input = event.target.value;
    // Allow only valid characters in a MAC address
    const sanitizedInput = input.replace(/[^a-fA-F0-9]/g, '');
    // Add colons automatically
    const formattedInput = sanitizedInput
      .substring(0, 12) // Limit input to a maximum of 12 characters
      .match(/.{1,2}/g);
    if (formattedInput) {
      // Check if formattedInput is not null
      const macAddress = formattedInput.join(':').toUpperCase();
      setMacAddressUpload(macAddress);
    } else {
      setMacAddressUpload('');
    }
  };

  const handleCheckboxChange = (event) => {
    setMacAddressUpload('');
    setMacAddressDelete('');
    setIsLGDevice(event.target.checked);
  };

  const handleLGAddressChange = (event) => {
    const macAddress = event.target.value;

    if (macAddress) {
      setMacAddressUpload(macAddress);
    } else {
      setMacAddressUpload('');
    }
  };

  const handleMacAddressDeleteChange = (event) => {
    const input = event.target.value;
    // Allow only valid characters in a MAC address
    const sanitizedInput = input.replace(/[^a-fA-F0-9]/g, '');
    // Add colons automatically
    const formattedInput = sanitizedInput
      .substring(0, 12) // Limit input to a maximum of 12 characters
      .match(/.{1,2}/g);
    if (formattedInput) {
      // Check if formattedInput is not null
      const macAddress = formattedInput.join(':').toUpperCase();
      setMacAddressDelete(macAddress);
    } else {
      setMacAddressDelete('');
    }
  };

  const handleLGAddressDeleteChange = (event) => {
    const input = event.target.value;

    if (input) {
      // Check if formattedInput is not null
      const macAddress = input;
      setMacAddressDelete(macAddress);
    } else {
      setMacAddressDelete('');
    }
  };

  const handleUpload = async () => {
    try {
      // Überprüfen, ob die MAC-Adresse bereits existiert
      const querySnapshot = await getDocs(
        query(
          collection(db, 'customers'),
          where('mac', '==', macAddressUpload),
        ),
      );

      if (!querySnapshot.empty) {
        // Das Dokument existiert bereits, also aktualisiere nur die Playlist-URLs
        const docRef = doc(db, 'customers', querySnapshot.docs[0].id);
        const docData = {
          playlistUrl: url1 || querySnapshot.docs[0].data().playlistUrl,
          playlistUrl2: url2 || querySnapshot.docs[0].data().playlistUrl2,
          playlistUrl3: url3 || querySnapshot.docs[0].data().playlistUrl3,
          newUrlUpload: true,
        };
        await updateDoc(docRef, docData);
      } else {
        // Das Dokument existiert nicht, also gib eine Fehlermeldung aus
        throw new Error('Die MAC-Adresse wurde nicht gefunden.');
      }

      setOpenModal(true);
    } catch (error) {
      setOpenModal(false);
      setOpenModalDelete(false);
      setOpenErrorModal(true);
    }
  };

  const isUploadDisabled = !macAddressUpload || (!url1 && !url2 && !url3);

  // Extract MAC address value from URL parameter
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const macAddress = urlParams.get('mac');
    const lgDevice = urlParams.get('lg') || 'false';
    if (macAddress && lgDevice == 'false') {
      handleMacAddressChange({ target: { value: macAddress } });
    }
    if (lgDevice && lgDevice === 'true') {
      setIsLGDevice(true);
      handleLGAddressChange({ target: { value: macAddress } });
    }
  }, []);

  return (
    <Container className="mylist-container" maxWidth="sm">
      <Typography variant="h4" component="h1" gutterBottom>
        Manage Playlists
      </Typography>
      <p>
        Please only upload playlists with a maximum size of 30MB-40MB.
        Otherwise, the app may crash. An update with a larger file size is
        coming soon.
      </p>

      <form className="mylist-form" onSubmit={handleSubmitUpload}>
        <FormControlLabel
          control={
            <Checkbox
              checked={isLGDevice}
              onChange={handleCheckboxChange}
              color="primary"
            />
          }
          label="I am using an LG or Android TV"
        />
        {!isLGDevice ? (
          <TextField
            label="MAC Address"
            fullWidth
            margin="normal"
            value={macAddressUpload}
            onChange={handleMacAddressChange}
          />
        ) : (
          <TextField
            label={isLGDevice ? 'Enter ID' : 'Enter MAC'}
            fullWidth
            margin="normal"
            value={macAddressUpload}
            onChange={handleLGAddressChange}
          />
        )}
        <TextField
          label="Playlist 1 URL"
          fullWidth
          margin="normal"
          value={url1}
          onChange={(e) => setUrl1(e.target.value.trim())}
        />
        <TextField
          label="Playlist 2 URL"
          fullWidth
          margin="normal"
          value={url2}
          onChange={(e) => setUrl2(e.target.value.trim())}
        />
        <TextField
          label="Playlist 3 URL"
          fullWidth
          margin="normal"
          value={url3}
          onChange={(e) => setUrl3(e.target.value.trim())}
        />
        <Button
          className="mylist-button"
          variant="contained"
          color="primary"
          type="submit"
          disabled={isUploadDisabled}
        >
          Upload
        </Button>
      </form>

      <Dialog open={openModal} onClose={handleCloseModal}>
        <DialogTitle>Upload Successful</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Your playlists have been uploaded successfully.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={openErrorModal} onClose={() => setOpenErrorModal(false)}>
        <DialogTitle>Error</DialogTitle>
        <DialogContent>
          <DialogContentText>The MAC address was not found.</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenErrorModal(false)} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={openModalDelete} onClose={handleCloseModalDelete}>
        <DialogTitle>Delete Successful</DialogTitle>
        <DialogContent>
          <DialogContentText>
            The playlist has been deleted successfully.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModalDelete} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default MyList;
