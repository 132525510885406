import React, { useState } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from '@mui/material';
import { FaAngleDown } from 'react-icons/fa';
import './Faq.css';

const FAQ = () => {
  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <div className="faq-container">
      <Typography variant="h4" component="h1" gutterBottom>
        Frequently Asked Questions
      </Typography>
      <Accordion
        expanded={expanded === 'panel1'}
        onChange={handleChange('panel1')}
      >
        <AccordionSummary expandIcon={<FaAngleDown />}>
          <Typography>What is the IPTV videoplayer app?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            The IPTV videoplayer app is a media player that lets you stream live
            and on-demand TV channels, movies, TV series, and other videos over
            the Internet. It supports various IPTV playlists and video formats
            and offers many advanced features.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === 'panel2'}
        onChange={handleChange('panel2')}
      >
        <AccordionSummary expandIcon={<FaAngleDown />}>
          <Typography>How to install the IPTV videoplayer app?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            To download the IPTV videoplayer app, search for "Royal IPTV 2.0" in
            your TV app store and follow the on-screen instructions. The app is
            available for various platforms, including Android TV, Samsung
            Tizen, LG WebOS, and Apple TV.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === 'panel3'}
        onChange={handleChange('panel3')}
      >
        <AccordionSummary expandIcon={<FaAngleDown />}>
          <Typography>How to use the IPTV videoplayer app?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            To use the IPTV videoplayer app, you need to add a playlist on our
            website in the correct format. Once you have added the playlist,
            restart the playlist on our app, and the content will load. You can
            then select the channels or videos you want. The app offers a
            user-friendly interface and supports various remote controls and
            input devices.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === 'panel4'}
        onChange={handleChange('panel4')}
      >
        <AccordionSummary expandIcon={<FaAngleDown />}>
          <Typography>
            Does the app come with any content? Where can I find a good
            playlist?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            No, the app does not come with any pre-loaded content. As an app and
            its administrators, we do not assist in finding or providing any
            playlists. We are not responsible for any content uploaded to our
            app. Please ensure that you have a playlist or media before
            purchasing the app, as no content is included after activation.
            Refunds will not be provided for any issues related to the lack of
            content or playlist.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === 'panel5'}
        onChange={handleChange('panel5')}
      >
        <AccordionSummary expandIcon={<FaAngleDown />}>
          <Typography>
            My MAC address has changed after I switched to another connection
            type.
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Each TV has two MAC addresses - the first is for WiFi and the second
            is for Ethernet. If your MAC address has changed after switching to
            another connection type, you need to activate the MAC address that
            you want to use. Please make sure to activate the correct MAC
            address in order to avoid any issues with your videoplayer app.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === 'panel6'}
        onChange={handleChange('panel6')}
      >
        <AccordionSummary expandIcon={<FaAngleDown />}>
          <Typography>
            Why is the app not working when the playlist worked on my computer?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            This could be due to the ISP lock, if your computer is connected to
            a different network than your TV. If your computer and TV are on the
            same network and the app is still not working, it may be due to the
            TV's supported format. Not all Smart TVs support all types of
            content, as each TV model is different and may not support specific
            stream formats.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === 'panel7'}
        onChange={handleChange('panel7')}
      >
        <AccordionSummary expandIcon={<FaAngleDown />}>
          <Typography>Why can't I start the app?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            This may be due to your internet connection. Please check your
            internet connection first before contacting your provider. Often,
            the issue is caused by the connection and not the provider.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === 'panel8'}
        onChange={handleChange('panel8')}
      >
        <AccordionSummary expandIcon={<FaAngleDown />}>
          <Typography>Does the App have an EPG system?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Yes, we have an integrated EPG system. If you want to use our EPG
            API service, you can visit this website: https://www.epg-api.com
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === 'panel9'}
        onChange={handleChange('panel9')}
      >
        <AccordionSummary expandIcon={<FaAngleDown />}>
          <Typography>Do you offer anything for business customers?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Yes, we offer everything from affiliate programs to advertising on
            our website or in the app. Please contact us at info@isi-soft.com
            for more information. We look forward to hearing from you!
          </Typography>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default FAQ;
