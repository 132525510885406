import React, { useEffect, useState } from 'react';
import {
  Button,
  TextField,
  Container,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  FormControlLabel,
  Checkbox,
} from '@mui/material';

const storeId = '83820766';
const accessToken = 'secret_RkRAnqZzPTarViGmB3f1rAK4PgB8T4cy';

const Activation = () => {
  const [macAddress, setMacAddress] = useState('');
  const [openModal, setOpenModal] = useState(false);
  const [isLGDevice, setIsLGDevice] = useState(false);

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleCheckboxChange = (event) => {
    setMacAddress('');
    setIsLGDevice(event.target.checked);
  };

  const handleLGAddressChange = (event) => {
    const macAddress = event.target.value;

    if (macAddress) {
      setMacAddress(macAddress);
    } else {
      setMacAddress('');
    }
  };

  const handleMacAddressChange = (event) => {
    const input = event.target.value;
    // Allow only valid characters in a MAC address
    const sanitizedInput = input.replace(/[^a-fA-F0-9]/g, '');
    // Add colons automatically
    const formattedInput = sanitizedInput
      .substring(0, 12) // Limit input to a maximum of 12 characters
      .match(/.{1,2}/g);
    if (formattedInput) {
      // Check if formattedInput is not null
      const macAddress = formattedInput.join(':').toUpperCase();
      setMacAddress(macAddress);
    } else {
      setMacAddress('');
    }
  };

  const handleActivate = async () => {
    // Perform your activation logic here, using the macAddress value
    console.log('MAC Address:', macAddress);

    // Add the MAC address as an option value to the product in your Ecwid store
    const productId = '524586664';
    const optionName = 'MAC ADDRESS';
    const optionValue = macAddress;
  };

  const isActivateDisabled = !macAddress;

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const macAddress = urlParams.get('mac');
    const lgDevice = urlParams.get('lg') || 'false';
    if (macAddress && lgDevice == 'false') {
      handleMacAddressChange({ target: { value: macAddress } });
    }
    if (lgDevice && lgDevice === 'true') {
      setIsLGDevice(true);
      handleLGAddressChange({ target: { value: macAddress } });
    }
  }, []);

  return (
    <Container className="activation-container" maxWidth="sm">
      <Typography variant="h4" component="h1" gutterBottom>
        Activation
      </Typography>
      <form className="activation-form" onSubmit={(e) => e.preventDefault()}>
        <FormControlLabel
          control={
            <Checkbox
              checked={isLGDevice}
              onChange={handleCheckboxChange}
              color="primary"
            />
          }
          label="I am using an LG or Android TV"
        />
        {!isLGDevice ? (
          <TextField
            label="MAC Address"
            fullWidth
            margin="normal"
            value={macAddress}
            onChange={handleMacAddressChange}
          />
        ) : (
          <TextField
            label={isLGDevice ? 'Check ID' : 'Check MAC'}
            fullWidth
            margin="normal"
            value={macAddress}
            onChange={handleLGAddressChange}
          />
        )}
        <Button
          className="activation-button"
          variant="contained"
          color="primary"
          onClick={handleActivate}
        >
          Activate
        </Button>
      </form>

      <Dialog open={openModal} onClose={handleCloseModal}>
        <DialogTitle>Activation Successful</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Your device has been successfully activated.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default Activation;
