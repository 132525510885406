import React from 'react';
import { Container, Box, Typography } from '@mui/material';

const PrivacyPolicy = () => {
  return (
    <Container maxWidth="md">
      <Box mt={4} mb={2}>
        <Typography variant="h4" align="center">
          Privacy Policy
        </Typography>
      </Box>
      <Typography variant="body1" paragraph>
        1. Introduction
        <p>
          This Privacy Policy explains how we, Isidev, collect, use, and protect
          your personal information when you use our Royal IPTV App service. We
          are committed to safeguarding your privacy and complying with German
          data protection laws and the General Data Protection Regulation
          (GDPR).
        </p>
        2. Data Controller
        <p>
          The Data Controller responsible for processing personal data is:
          Isidev, Bergmannstr. 191A, 45886 Gelsenkirchen, Germany,
          info@isi-soft.com
        </p>
        3. Personal Data Collected
        <p>
          We collect the following types of personal data when you register for
          our Royal IPTV App service: <p>- Name</p> <p>- Email address</p>
          <p>- Billing information</p> We use this data to provide you with
          access to our Royal IPTV App service, manage your account, process
          payments, and communicate with you about our services.
        </p>
        4. Legal Basis for Processing
        <p>
          The legal basis for processing your personal data is the performance
          of a contract (Article 6(1)(b) GDPR). When you register for our EPG
          API service, we enter into a contract with you, and processing your
          personal data is necessary to fulfill our contractual obligations.
        </p>
        5. Data Retention
        <p>
          We retain your personal data for as long as your account is active or
          as needed to provide you with our services. We may also retain your
          data for a longer period to comply with legal obligations or to
          protect our legitimate interests.
        </p>
        6. Data Recipients and Transfers
        <p>
          We may disclose your personal data to the following categories of
          recipients: <p>- Payment processors to handle payment transactions</p>
          <p>
            - Service providers who help us operate our website and Royal IPTV
            App service
          </p>
        </p>
        7. Data Subject Rights
        <p>
          Under the GDPR, you have the following rights regarding your personal
          data:
          <p>
            - Right to access: You have the right to obtain a copy of your
            personal data.
          </p>
          <p>
            - Right to rectification: You can request the correction of
            inaccurate personal data.
          </p>
          <p>
            - Right to erasure: You can request the deletion of your personal
            data under certain circumstances.
          </p>
          <p>
            - Right to restriction of processing: You can request that we
            temporarily or permanently stop processing your personal data under
            certain circumstances.
          </p>
          <p>
            - Right to data portability: You can request a copy of your personal
            data in a structured, commonly used, and machine-readable format.
          </p>
          <p>
            - Right to object: You can object to the processing of your personal
            data under certain circumstances.
          </p>
          To exercise any of your rights, please contact us using the
          information provided in the Contact Information section below.
        </p>
        8. Withdrawal of Consent
        <p>
          If we have relied on your consent to process your personal data, you
          have the right to withdraw that consent at any time. To withdraw your
          consent, please contact us using the information provided in the
          Contact Information section below.
        </p>
        9. Right to Lodge a Complaint
        <p>
          You have the right to lodge a complaint with a supervisory authority
          if you believe that our processing of your personal data violates data
          protection laws. In Germany, the supervisory authority is the Federal
          Commissioner for Data Protection and Freedom of Information (BfDI).
        </p>
        10. Data Security Measures
        <p>
          We have implemented appropriate technical and organizational measures
          to protect your personal data from unauthorized access, alteration,
          disclosure, or destruction. These measures include secure data
          storage, encrypted data transmission, and regular security
          assessments.
        </p>
        11. Use of Cookies and Similar Technologies
        <p>
          Our website uses cookies and similar technologies to improve user
          experience, analyze website usage, and ensure the proper functioning
          of our services. By using our website, you consent to the use of
          cookies and similar technologies in accordance with our Cookie Policy.
        </p>
        12. Third-Party Services
        <p>
          Our Royal IPTV App service may use third-party services to enhance its
          functionality, such as analytics tools, social media plugins, or
          advertising networks. These third-party services may collect personal
          data independently, and their data processing practices are governed
          by their respective privacy policies. We encourage you to review these
          policies to understand how your data may be processed by these third
          parties.
        </p>
        13. Changes to the Privacy Policy
        <p>
          We reserve the right to make changes to this Privacy Policy at any
          time. We will notify you of any significant updates by email or by
          posting a notice on our website. Your continued use of our Royal IPTV
          App service after any changes to the Privacy Policy constitutes your
          acceptance of the updated policy.
        </p>
        14. Contact Information
        <p>
          If you have any questions, concerns, or requests related to your
          personal data or this Privacy Policy, please contact us at: Isidev,
          Bergmannstr. 191A, 45886 Gelsenkirchen, Germany, info@isi-soft.com
        </p>
        <p>Last updated: 02.06.2023</p>
      </Typography>
      {/* Add more content related to your Privacy Policy here */}
    </Container>
  );
};

export default PrivacyPolicy;
